import React from 'react';
import { ArticlesProvider } from './src/context/ArticlesContext';
import { FormSubmissionProvider } from './src/context/FormSubmissionContext';

export const wrapRootElement = ({ element }) => {
  return (
    <ArticlesProvider>
      <FormSubmissionProvider>{element}</FormSubmissionProvider>
    </ArticlesProvider>
  );
};
