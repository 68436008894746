import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';

// Create the context
const FormSubmissionContext = createContext();

// Create a provider component
export const FormSubmissionProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    usage: '',
    comments: ''
  });
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle form submission directly to Google Apps Script
  const submitForm = async (data) => {
    setIsLoading(true);
    setError(null);
    try {
      const googleScriptUrl = 'https://script.google.com/macros/s/AKfycbyoLoSN6BJcBUNPwaBRI0LofiI_N57o6ZWlFn1eg0TWH_JM-BWEmXIyIBf9q_N_UNLw/exec';
      const response = await axios.post(googleScriptUrl, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setResponse(response.data);
    } catch (error) {
      setError('Failed to submit the form: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormSubmissionContext.Provider
      value={{
        formData,
        setFormData,
        submitForm,
        response,
        error,
        isLoading,
      }}
    >
      {children}
    </FormSubmissionContext.Provider>
  );
};

// Custom hook to use the FormSubmission context
export const useFormSubmission = () => {
  return useContext(FormSubmissionContext);
};
