import React, { createContext, useState, useContext } from 'react';

// Import images
import blog1 from '../images/blog1.png'; // Adjust the paths and file extensions as needed
import blog2 from '../images/blog2.jpg';
import blog3 from '../images/blog3.jpg';

// Create the context
const ArticlesContext = createContext();

// Default articles to be displayed
const defaultArticles = [
  {
    title: "Inside the White House Solar Panels",
    url: "https://www.energy.gov/eere/solar/articles/inside-white-house-solar-panels",
    description: "A look into the solar panels installed at the White House, showcasing the shift towards renewable energy in government buildings.",
    source: { name: "Department of Energy" },
    urlToImage: blog2, // Use the imported image variable
    publishedAt: "2024-08-01",
  }, {
  title: "Top US States for Renewable Power Generation",
  url: "https://www.reuters.com/sustainability/top-us-states-renewable-power-generation-capacity-maguire-2024-07-25/",
  description: "An overview of the leading US states in renewable power generation capacity, highlighting the progress in sustainable energy.",
  source: { name: "Reuters" },
  urlToImage: blog1, // Use the imported image variable
  publishedAt: "2024-08-01",
  }
  ,
  {
    title: "Getting the Most Out of Solar Panels",
    url: "https://www.energy.gov/articles/getting-most-out-solar-panels",
    description: "Discover tips and strategies to maximize the efficiency and output of your solar panels for optimal energy savings.",
    source: { name: "Department of Energy" },
    urlToImage: blog3, // Use the imported image variable
    publishedAt: "2024-08-01",
  },
];

// Create a provider component
export const ArticlesProvider = ({ children }) => {
  const [articles, setArticles] = useState(defaultArticles);

  return (
    <ArticlesContext.Provider value={{ articles, setArticles }}>
      {children}
    </ArticlesContext.Provider>
  );
};

// Create a custom hook to use the Articles context
export const useArticles = () => {
  return useContext(ArticlesContext);
};
